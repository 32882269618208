import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://pc.modularlabs.tech"
          rel="noopener noreferrer"
        >
          DESKTOP VERSION
        </a>
        <a
          className="App-link"
          href="https://m.modularlabs.tech"
          rel="noopener noreferrer"
        >
          MOBILE VERSION
        </a>
      </header>
    </div>
  );
}

export default App;
